import { Injectable, inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from '@core/services/alert.service'
import { ReleaseNote } from '@modules/update/models/release-note.model'
import { switchMap, tap } from 'rxjs'
import { ReleaseNoteApiService } from '../services/release-note-api.service'

@Injectable({
    providedIn: 'root',
})
export class ReleaseNoteFormService {
    private releaseNoteApiService = inject(ReleaseNoteApiService)
    private alertService = inject(AlertService)
    private router = inject(Router)

    releaseNoteForm: FormGroup = new FormGroup({
        version: new FormControl<string>('', [Validators.required, Validators.minLength(3)]),
        title: new FormControl<string>('', [Validators.required]),
        content: new FormControl<string>('', [Validators.required]),
        releaseDate: new FormControl<Date>(null, [Validators.required]),
    })

    get hasTextareaMinLengthError() {
        return this.releaseNoteForm.get('version')?.hasError('minlength')
    }

    submitReleaseNote() {
        if (this.releaseNoteForm.invalid) {
            this.alertService.warn('Please fill all required fields.')
        }

        this.alertService
            .confirm('Create Article?', 'Are you sure you want to create this Article?', 'Create')
            .pipe(
                switchMap(() => {
                    return this.releaseNoteApiService.create(this.releaseNoteForm.value)
                }),
            )
            .subscribe({
                next: (update) => {
                    this.alertService.success('Created successfully')
                    this.releaseNoteForm.reset()
                    this.router.navigate(['/admin/release-notes'])
                    return update
                },
                error: (err) => {
                    this.alertService.error('Error while trying to delete article')
                },
            })
    }

    updateReleaseNote(id: string) {
        this.alertService
            .confirm('Update Article?', 'Are you sure you want to update this Article?', 'Update')
            .pipe(
                switchMap(() => {
                    return this.releaseNoteApiService.updateReleaseNote(
                        id,
                        this.releaseNoteForm.value,
                    )
                }),
            )
            .subscribe({
                next: (update) => {
                    this.alertService.success('Updated successfully')
                    this.releaseNoteForm.reset()
                    this.router.navigate(['/admin/release-notes'])
                    return update
                },
                error: (err) => {
                    console.log(err)
                    this.alertService.error(
                        'Error while trying to update article',
                        err.error.message,
                    )
                },
            })
    }
}
